import React from "react";

function EmailIcon(props) {
  return (
    <svg
      className={`emailIcon ${props.className || ""}`}
      fill="#c9cacc"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 50 50"
    >
      <path d="M43.754 6.402A5.478 5.478 0 0040.27 7.47l-2.746 2.027L25 18.756 12.592 9.584a1 1 0 00-.643-.283h.25L9.734 7.477a5.48 5.48 0 00-3.486-1.07 5.034 5.034 0 00-3.34 1.439A6.403 6.403 0 001 12.41v3.434a1 1 0 000 .295V39.5C1 41.421 2.579 43 4.5 43H12a1 1 0 001-1V25.373l11.406 8.432a1 1 0 001.188 0L37 25.373V42a1 1 0 001 1h7.5c1.921 0 3.5-1.579 3.5-3.5V16.12a1 1 0 000-.26v-3.45a6.414 6.414 0 00-1.902-4.566 1 1 0 00-.006-.006 5.037 5.037 0 00-3.338-1.436zm-.11 1.998c.757.03 1.49.317 2.045.864l.024.023A4.402 4.402 0 0147 12.41v3.086l-8 5.912V11a1 1 0 00-.004-.102l2.463-1.82a1 1 0 00.002 0 3.461 3.461 0 012.184-.678zm-37.287.006a3.464 3.464 0 012.188.678l2.459 1.818A1 1 0 0011 11v10.408l-8-5.912V12.41c0-1.236.502-2.341 1.313-3.14a3.059 3.059 0 012.044-.864zM37 12.371v10.516l-12 8.869-12-8.87V12.374l11.406 8.432a1 1 0 001.188 0L37 12.37zM3 17.982l8 5.914V41H4.5c-.84 0-1.5-.66-1.5-1.5V17.982zm44 0V39.5c0 .84-.66 1.5-1.5 1.5H39V23.896l8-5.914z"></path>
    </svg>
  );
}

export default EmailIcon;
