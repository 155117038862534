import Search from "./search";

function NavBar() {
  return (
    <div className="NavBar">
      <div className="logo-container">
        <a href="/">
          <img className="logo-image" src="images/logo.png" alt="logo-pic" />{" "}
        </a>
      </div>
      <div className="navigation-container">
        <a href="/discover" className="link">
          Discover{" "}
        </a>
        <span></span>
        <a href="/feature" className="link">
          Features{" "}
        </a>
        <span></span>
        <a href="/topics" className="link">
          Topics{" "}
        </a>
        <span></span>
        <a href="/food" className="link">
          Food & Shopping{" "}
        </a>
        <a href="/travel" className="link">
          Travel{" "}
        </a>
        <a href="/aboutus" className="link">
          About us{" "}
        </a>
      </div>
      <Search />
    </div>
  );
}

export default NavBar;
