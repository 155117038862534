import SearchIcon from "../icons/searchIcon";

function Search() {
  return <div className="search-container">
    <SearchIcon />
    <p>Search Keyword</p>
  </div>;
}

export default Search;
