import FacebookIcon from "../icons/facebook";
import LinkedInIcon from "../icons/linkedinIcon";
import TwitterIcon from "../icons/twitterIcon";
import EmailIcon from "../icons/emailIcon";

function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <div>
      <footer className="footer">
        <img src="images/logo.png" alt="logo" className="logo-footer" />
        <div className="footer-wrapper">
          <h5>POWERED BY NIK CONSULTING @{year}</h5>
          <h6>VIEW JAPAN FROM INSIDE - RIGHT THROUGH THE WINDOW</h6>
        </div>
        <div className="icons-container">
  
        </div>
        <div class="columns">
          <div class="column">
            {" "}
            <FacebookIcon />
          </div>
          <div class="column">
            {" "}
            <LinkedInIcon />
          </div>
          <div class="column">
            {" "}
            <TwitterIcon />
          </div>
          <div class="column">
            {" "}
            <EmailIcon />
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
