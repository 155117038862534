import { Routes, Route } from "react-router-dom";
import AboutUs from "../pages/abousUs";
import Discover from "../pages/discover";
import Features from "../pages/features";
import FoodAndShopping from "../pages/foodAndShopping";
import Home from "../pages/home";
import Topics from "../pages/topics";
import Travel from "../pages/travel";

function AppRoutes(props) {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/discover" element={<Discover />} />
      <Route path="/feature" element={<Features />} />
      <Route path="/topics" element={<Topics />} />
      <Route path="/travel" element={<Travel />} />
      <Route path="/food" element={<FoodAndShopping />} />
  
    </Routes>
  );
}

export default AppRoutes;
