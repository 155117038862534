import { Link } from "react-router-dom";
import DarkVariantExample from "../components/carousel";
import Footer from "../components/footer";
import NavBar from "../components/navbar";
import ArticalesCarousel from "../components/searchCarousel";

function Home() {
  const images = [
    {
      src: "/images/food.png",
      alt: "food-pic",
    },
    {
      src: "images/travel.png",
      alt: "travel-pic",
    },
    {
      src: "images/shopping.png",
      alt: "shopping",
    },
  ];

  return (
    <div className="home-container">
      <NavBar />
      <div className="home-banner">
        <div className="start-image-container-1">
          <img
            className="star"
            src="images/image_2023_02_15T02_56_35_602Z.png"
            alt="star-home-1"
          />
        </div>
        <p className="title">Your Next Destination is</p>
        <div className="start-image-container-2">
          <img
            className="star"
            src="images/image_2023_02_15T02_56_53_120Z.png"
            alt="star-home-2"
          />
        </div>

        <p className="city-title">Japan </p>
        <div className="banner-container">
          <p className="sub-title">Exploar</p>
          <img
            className="banner-image"
            src="/images/image_2023_02_13T20_25_49_011Z.png"
            alt="img-banner"
          />
        </div>
      </div>

      <DarkVariantExample />

      <div className="container">
        <h1>Discover how amazing Japan is!</h1>
        <div className="discover-wrapper">
          {images.map((image, index) => (
            <img
              key={index}
              className="options"
              src={image.src}
              alt={image.alt}
            />
          ))}
        </div>
        <h1>Search Through Our Articales </h1>
        <ArticalesCarousel />
        <h1>Picture Galarry</h1>
        <div className="galarry-container">
          <div className="flex">
            <img
              src="images/Rectangle-10.jpg"
              alt="japan-6"
              className="japan-6"
            />
            <img
              src="images/image_2023_02_13T20_27_29_827Z.png"
              alt="japan-2"
              className="japan-2"
            />
            <img
              src="images/image_2023_02_13T20_27_48_290Z.png"
              alt="japan-4"
              className="japan-4"
            />
          </div>
          <div className="flex">
            <img
              src="images/image_2023_02_13T20_27_24_854Z.png"
              alt="japan-3"
              className="japan-3"
            />

            <img
              src="images/image_2023_02_13T20_27_15_771Z.png"
              alt="japan.1"
              className="japan-1"
            />

            <img
              src="images/image_2023_02_13T20_27_20_614Z.png"
              alt="japan-5"
              className="japan-5"
            />
          </div>
        </div>
      </div>

      <div className="grey-area">
        <div className="sub-conatiner-area">
          <h4>Search by Purpose</h4>
          <Link className="links" to="/shopping">
            Shopping{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/shopping">
            Food{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/shopping">
            Travel
          </Link>
        </div>
        <div className="sub-conatiner-area">
          <h4>Search by Article</h4>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
        </div>
        <div className="sub-conatiner-area">
          <h4>Search by Area</h4>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
          <span>| </span>
          <Link className="links" to="/">
            lorem{" "}
          </Link>
        </div>
      </div>
      <Footer />
  
    </div>
  );
}

export default Home;
