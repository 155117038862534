import "./App.css";
import AppRoutes from "./router/appRuotes";
import 'bootstrap/dist/css/bootstrap.min.css';

function App(props) {
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
